$(function () {

  $('.carousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
  });


  $("#interview-button").click(function () {
    $("#asr2021-interview-modal").addClass("show");
  });

  $("#asr2021-interview-modal .btn-close").click(function () {
    $("#asr2021-interview-modal").removeClass("show");
  });

  $("#asr2021-downloads-modal .btn-close").click(function () {
    $("#asr2021-downloads-modal").removeClass("show");
  });

  $(".download-button").click(function () {
    $("#asr2021-downloads-modal").addClass("show");
  });
});

$(document).ready(function () {

  $("#accordion a").click(function() {
    var link = $(this);
    var closest_ul = link.closest("ul");
    var parallel_active_links = closest_ul.find(".active")
    var closest_li = link.closest("li");
    var link_status = closest_li.hasClass("active");
    var count = 0;

    closest_ul.find("ul").slideUp(function() {
      if (++count == closest_ul.find("ul").length)
        parallel_active_links.removeClass("active");
    });

    if (!link_status) {
      closest_li.children("ul").slideDown();
      closest_li.addClass("active");
    }
  })

    $('.sub-nav > a').click(function() {
      if ($(this).parent('.sub-nav').hasClass('open')) {
        $(this).parent('.sub-nav').removeClass('open');
        $(this).parent('.sub-nav').children('ul').slideUp("slow");
      } else {
        $(this).parent('.sub-nav').addClass('open');
        $(this).parent('.sub-nav').children('ul').slideDown("slow");
      };
    });

  $(".asr-slider .asr-slide").on("mouseover", function () {
    $('.asr-slider').addClass("active");
    $('.asr-slider .asr-slide').removeClass("active");
    $(this).addClass("active");
  });

  $(".asr-slider").on("mouseleave", function () {
    $('.asr-slider .asr-slide').removeClass("active");
    $('.asr-slider').removeClass("active");
  });
});

$(window).scroll(function () {
  $('.bar-inner').each(function () {
    if (isScrolledIntoView(this) === true) {
      setTimeout(function () {
        $(this).removeClass('show');
      }.bind(this), 500);
    }
  });

  if ($(window).scrollTop() >= 10) {
    $(".home .logo").addClass("scrolled");
    $(".asr2021-breadcrumbs").addClass("scrolled");
  } else {
    $(".home .logo").removeClass("scrolled");
    $(".asr2021-breadcrumbs").removeClass("scrolled");
  }
});

function isScrolledIntoView(elem) {
  var docViewTop = $(window).scrollTop();
  var docViewBottom = docViewTop + $(window).height();

  var elemTop = $(elem).offset().top;
  var elemBottom = elemTop + $(elem).height();

  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
